import React, { ReactNode, useCallback, useState } from 'react'
import { IGatsbyPage } from 'gatsby/internal'
import classNames from 'classnames'
import Row from 'Components/Row/Row'
import Text, { TextProps } from 'Components/texts/Text'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Button, { ButtonProps } from 'Components/touchables/Button'
import TestIcon from 'Assets/icon-filters.inline.svg'
import AltPageLayout from 'Components/layout/AltPageLayout'
import Menu from 'Components/layout/Menu'
import Input, { InputProps } from 'Components/inputs/Input/Input'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styles from './BrandingPage.module.scss'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'

const BrandingPage = ({ path }: IGatsbyPage) => {
  const { t } = useTranslation()

  useMaybeChangeLanguageOnInit()

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={{ meta_title: 'Branding', meta_description: '' }}
        path={path}
        title="Branding"
        subTitle={t('branding.base_ui') ?? ''}
        firstSectionHasLabel={false}
      >
        <BaseContainer>
          <Block label="Texts" Component={Texts} />
          <Block label="Buttons" Component={Buttons} themes={['base', 'alt']} />
          <Block label="Inputs" Component={Inputs} themes={['base', 'alt']} />
        </BaseContainer>
      </AltPageLayout>
    </>
  )
}

function Block({
  label,
  Component,
  themes,
}: {
  label: string
  Component: any
  themes?: string[]
}) {
  const _themes: string[] = Array.isArray(themes) ? themes : ['base']

  return (
    <div className={styles.block}>
      <h2>{label}</h2>
      {_themes.map((theme) => (
        <div
          key={theme}
          className={classNames([
            styles.blockContent,
            theme === 'alt' && styles.blockContentAlt,
          ])}
        >
          <Component theme={theme} />
        </div>
      ))}
    </div>
  )
}

type ItemsProps = { theme: 'base' | 'alt' }

function Texts({ theme }: ItemsProps) {
  const renderText = useCallback((props: TextProps) => {
    return <Text {...props}>{`This is a test text (${props.variant || ''})`}</Text>
  }, [])

  return (
    <div>
      <Label>{theme}</Label>
      <div className={styles.items}>
        {renderText({ variant: 'heading' })}
        {renderText({ variant: 'md' })}
        {renderText({ variant: 'md-bold' })}
        {renderText({ variant: 'sm' })}
        {renderText({ variant: 'sm-bold' })}
      </div>
    </div>
  )
}

function Buttons({ theme }: ItemsProps) {
  const renderButtons = (postfix: string, props: ButtonProps) => {
    const _postfix = postfix ? ` + ${postfix}` : ''
    return (
      <Row wrap className={styles.items}>
        <Button {...props} title={`Filled${_postfix}`} theme={theme} variant="filled" />
        <Button {...props} iconStart={TestIcon} theme={theme} variant="filled" />
        <Button {...props} title={`Outline${_postfix}`} theme={theme} variant="outline" />
        <Button {...props} iconStart={TestIcon} theme={theme} variant="outline" />
        <Button {...props} title={`Flat${_postfix}`} theme={theme} variant="flat" />
        <Button {...props} iconStart={TestIcon} theme={theme} variant="flat" />
      </Row>
    )
  }

  return (
    <div>
      <Label>{theme}</Label>
      {renderButtons('', { size: 'base' })}
      {renderButtons('Small', { size: 'small' })}
      {renderButtons('Disabled', { size: 'base', disabled: true })}
    </div>
  )
}

function Inputs({ theme }: ItemsProps) {
  const { t } = useTranslation()

  const InputsRow = (postfix: string, props: Partial<InputProps>) => {
    const [value, setValue] = useState(props.multiple ? [] : '')
    const _postfix = postfix ? ` + ${postfix}` : ''
    return (
      <Input
        {...props}
        className={styles.input}
        value={value}
        onChange={(data) => setValue(data.value)}
        label={`Input${_postfix}`}
        theme={theme}
      />
    )
  }

  const options = [1, 2, 3].map((i) => ({
    label: [t('branding.option'), i].join(' '),
    value: ['option', i].join('_'),
  }))

  return (
    <div>
      <Label>{theme}</Label>
      <Row className={styles.items} vertical="top" wrap>
        {InputsRow('', {})}
        {InputsRow('Select', { options })}
      </Row>
    </div>
  )
}

function Label({ children }: { children: ReactNode }) {
  return (
    <Text className={styles.label} variant="sm">
      {children}
    </Text>
  )
}

export default BrandingPage
