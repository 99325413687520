import BrandingPage from 'Views/branding/BrandingPage'
import React from 'react'
import { IGatsbyPage } from 'gatsby/internal'
import { graphql } from 'gatsby'

export default function Branding(props: IGatsbyPage) {
  return <BrandingPage {...props} />
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
